<template>
  <div class="auth-wrapper auth-v2" :style="backgroundStyle">
    <b-row class="auth-inner m-0" align-v="center">
      <!-- Login-->
      <b-col md="4" offset-md="4" class="d-flex align-items-center p-0">
        <b-card class="px-2 mx-auto auth-bg w-100">
          <safe-img :src="communityLogo" class="reset-logo mb-3 mt-2" />
          <h1 class="font-weight-bold w-100 text-center mb-2" title-tag="h1">
            {{ translate(community.name) }}
          </h1>

          <div v-if="success === false">
            <b-card-text class="text-center h3">
              {{ $t('reset.subtitle') }}
            </b-card-text>

            <!-- form -->
            <validation-observer ref="resetForm" #default="{invalid}">
              <b-form class="auth-reset-form mt-2" @submit.prevent="reset">
                <!-- forgot password 1-->
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('reset.form.password.label')"
                    vid="password"
                    rules="required"
                  >
                    <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                      <b-form-input
                        id="reset-password"
                        v-model="password"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="reset-password"
                        :placeholder="$t('reset.form.password.placeholder')"
                        :aria-label="$t('reset.form.password.label')"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password 2-->
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('reset.form.password2.label')"
                    vid="password"
                    rules="required"
                  >
                    <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                      <b-form-input
                        id="reset-password2"
                        v-model="password2"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="reset-password2"
                        :placeholder="$t('reset.form.password2.placeholder')"
                        :aria-label="$t('reset.form.password2.label')"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- submit buttons -->
                <b-button
                  class="mt-2 boton"
                  type="submit"
                  squared
                  variant="primary"
                  block
                  :disabled="invalid"
                >
                  {{ $t('reset.form.action.reset') }}
                </b-button>
              </b-form>
            </validation-observer>
          </div>
          <div v-else>
            <b-alert variant="success" show>
              {{ $t('reset.form.action.success') }}
            </b-alert>
          </div>

          <b-card-text class="text-center mt-2 small mb-0">
            <b-link @click="$router.push({ path: `/${communitySlug}/login` })">
              <span>&nbsp;{{ $t('forgot.return') }}</span>
            </b-link>
          </b-card-text>

          <b-card-text class="text-center  mt-3">
            <b-link :href="landingURL" target="_blank">
              <b-img :src="brand" alt="Powered by Nectios" class="brand" />
            </b-link>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
  BSpinner,
  BRow,
  BCol,
  BCard,
  BAlert,
  BLink,
  BImg,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardText,
  BForm,
  BButton,
  VBTooltip,
} from 'bootstrap-vue';
import { required, email } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { getImageResource } from '@/@core/utils/image-utils';
import SafeImg from '@core/components/safe-img/SafeImg.vue';
import Service from '@/config/service-identifiers';
import BrandLogo from '@/assets/images/brand/powerby-nectios.svg';
import { getNewAuthRoute } from '@/auth/utils';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BSpinner,
    BRow,
    BCol,
    BCard,
    BAlert,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BForm,
    BButton,
    BImg,
    SafeImg,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility, ToastNotificationsMixin],
  data() {
    return {
      success: false,
      password: '',
      password2: '',
      userEmail: '',
      community: null,

      // validation rules
      required,
      email,

      isLoading: true,

      from: null,
      path: null,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    backgroundStyle() {
      const backgrounds = this.community?.customization?.theme?.login?.backgrounds || [];

      if (backgrounds.length) {
        return {
          backgroundImage: `url(${getImageResource(backgrounds[0])}`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat !important',
        };
      }

      return null;
    },
    user() {
      return this.$store.getters.loggedUser;
    },
    communitySlug() {
      return this.$route.params.communityId;
    },
    token() {
      return this.$route.query.token;
    },
    brand() {
      return BrandLogo;
    },
    landingURL() {
      return process.env.VUE_APP_LANDING_URL_COMMUNITY;
    },
    communityLogo() {
      return getImageResource(
        this.community.customization?.theme?.login?.logo || this.community.header?.logo_url || this.community.logoURL,
      );
    },
  },
  created() {
    this.updateCommunity();
    if (process.env.VUE_APP_ENV !== 'development') {
      window.location.href = getNewAuthRoute({ ...this.community, slug: this.communitySlug }, `/reset?token=${this.token}`);
    }
  },
  methods: {
    translate(field) {
      return translateTranslationTable(this.$store.state.locale.currentLocale, field);
    },
    async updateCommunity() {
      this.isLoading = true;

      const response = await this.$store.$service[Service.BackendClient].get('login', {
        params: { communitySlug: this.communitySlug },
      });
      this.community = response.data.data;
      /* Community colors */
      if (this.community.community_customizations.theme) {
        const customization = this.community.community_customizations.theme;
        if (customization.primaryColor) {
          document.documentElement.style.setProperty(
            '--primary-color',
            customization.primaryColor,
          );
          let hsl = customization.primaryColor;
          hsl = hsl.substring(4, hsl.length - 1);
          hsl = hsl.split(',');
          document.documentElement.style.setProperty(
            '--primary-color-h',
            hsl[0],
          );
          document.documentElement.style.setProperty(
            '--primary-color-s',
            hsl[1],
          );
          document.documentElement.style.setProperty(
            '--primary-color-l',
            hsl[2],
          );
        }
        if (customization.secondaryColor) {
          document.documentElement.style.setProperty(
            '--secondary-color',
            customization.secondaryColor,
          );
          let hslSecondary = customization.secondaryColor;
          hslSecondary = hslSecondary.substring(4, hslSecondary.length - 1);
          hslSecondary = hslSecondary.split(',');
          document.documentElement.style.setProperty(
            '--secondary-color-h',
            hslSecondary[0],
          );
          document.documentElement.style.setProperty(
            '--secondary-color-s',
            hslSecondary[1],
          );
          document.documentElement.style.setProperty(
            '--secondary-color-l',
            hslSecondary[2],
          );
        }
      }
      /* Finish colors */
      if (this.community.mainCommunitySlug && this.community.mainCommunitySlug !== this.communitySlug && !this.community.bypass) {
        this.from = this.communitySlug;
        this.path = this.$route.params.path;
        await this.$router.replace({
          name: 'auth-login',
          params: { communityId: this.community.mainCommunitySlug },
        });

        return;
      }

      this.isLoading = false;
      this.setLocale();
    },
    async reset() {
      if (this.password === this.password2) {
        try {
          const response = await this.$store.dispatch('recoverPassword', {
            password: this.password,
            repeatPassword: this.password2,
            communitySlug: this.communitySlug,
            token: this.token,
          });
          this.comprobacion(response);
        } catch (error) {
          this.notifyError(this.$t('error-message.general-error'));
        }
      } else {
        this.notifyError(this.$t('reset.form.password-error'));
      }
      return '';
    },

    comprobacion(response) {
      if (response.data.ok != null) {
        this.success = true;

        setTimeout(() => this.reedirect(), 6000);
      }
    },

    reedirect() {
      this.$router.push({ path: `/${this.communitySlug}/login` });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-auth.scss';
.reset-logo {
  width: 120px;
  margin: auto;
  display: block;
}
.brand {
  height: 32px;
}
.community-text {
  padding-left: 6px;
  margin-top: 4px;
  vertical-align: bottom;
  color: black;
}
.boton {
  font-size: 1.3em;
}
</style>
